import { Box, Heading, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import privacy from '../Privacy_Policy/privacy.png'
const Privacy_Policy = () => {
    return (
        <>
            {/* <Box w={"100%"}  >
               
                <Image src={privacy} alt={'privacy'} w={'100%'}  objectFit={'cover'} />

            </Box> */}
            <VStack  w={'90%'} m={'auto'} p={'30px 0px'} alignItems={'flex-start'} className='privacyBack'>
                <Heading fontSize={'1.4rem'} children={'Privacy Policy for Aahana'} />
                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Privacy Policy'} />
                {/* <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'Last updated: [Insert Date]'} /> */}
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={`This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use our services and informs You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve our services. By using our services, You agree to the collection and use of information in accordance with this Privacy Policy.`} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Interpretation and Definitions'} />
                <Text fontSize={'1.2rem'} fontWeight={'600'} children={'Interpretation'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'The words with the initial letter capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.'} />

                <Text fontSize={'1.2rem'} fontWeight={'600'} children={'Definitions'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'For the purposes of this Privacy Policy:'} />

                <ul>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Account means a unique account created for You to access our services or parts of our services.</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Affiliate means an entity that controls, is controlled by, or is under common control with a party, where “control” means ownership of 50% or more of the shares or voting rights.</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Company (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Aahana, [821, 8th Floor, Gaur City Center, near Charmurti Chowk and Gaur City Mall].</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Cookies are small files that are placed on Your computer, mobile device, or any other device by a website, containing details of Your browsing history on that website among other uses.</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Country refers to: [Insert Country].</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Device means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Personal Data is any information that relates to an identified or identifiable individual.</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Service refers to the Website.</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Service Provider means any natural or legal person who processes the data on behalf of the Company.</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>Website refers to Aahana, accessible from [Insert URL].</li>
                    <li style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                </ul>

                <Text fontSize={'1.2rem'} mt={5} fontWeight={'600'}>
                    Collecting and Using Your Personal Data
                </Text>

                {/* Types of Data Collected */}
                <Text fontSize={'1.2rem'} fontWeight={'600'}>
                    Types of Data Collected
                </Text>

                {/* Personal Data */}
                <Text fontSize={'1.2rem'} fontWeight={'600'}>
                    Personal Data
                </Text>
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'}>
                    While using Our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:
                </Text>
                <ul style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem' }}>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Usage Data</li>
                </ul>

                {/* Usage Data */}
                <Text fontSize={'1.2rem'} mt={4} fontWeight={'600'}>
                    Usage Data
                </Text>
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'}>
                    Usage Data is collected automatically when using the Service. It may include information such as your device’s Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, time spent on those pages, unique device identifiers, and other diagnostic data.
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    When you access the Service via a mobile device, we may collect information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, and the type of mobile Internet browser you use.
                </Text>

                {/* Tracking Technologies and Cookies */}
                <Text fontSize={'1.2rem'} mt={4} fontWeight={'600'}>
                    Tracking Technologies and Cookies
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    We use cookies and similar tracking technologies to track the activity on our Service and store certain information. Tracking technologies used include beacons, tags, and scripts.
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    <strong>– Cookies or Browser Cookies:</strong> A cookie is a small file placed on your device. You can instruct your browser to refuse all cookies. However, if you do not accept cookies, you may not be able to use some parts of our Service.
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    <strong>– Web Beacons:</strong> Certain sections of our Service and emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs).
                </Text>

                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on your device when you go offline, while Session Cookies are deleted when you close your web browser.
                </Text>

                {/* Purpose of Cookies */}
                <Text fontSize={'1.2rem'} mt={4} fontWeight={'600'}>
                    Use of Your Personal Data
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    The Company may use Personal Data for the following purposes:
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    <strong>To provide and maintain our Service:</strong> including monitoring the usage of our Service.
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    <strong>To manage Your Account:</strong> to manage your registration as a user of the Service.
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    <strong>For the performance of a contract:</strong> the development, compliance, and undertaking of the purchase contract for the products, items, or services you have purchased.
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    <strong>To contact You:</strong> to contact you by email, phone, SMS, or other electronic communication methods.
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    <strong>To provide You</strong> with news, special offers, and general information about other goods, services, and events.
                </Text>

                {/* Sharing of Personal Data */}
                <Text fontSize={'1.1rem'} mt={5} fontWeight={'400'} color={'grey'}>
                    We may share your personal information in the following situations:
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    <strong>With Service Providers:</strong> to monitor and analyze the use of our Service.
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    <strong>For business transfers:</strong> to manage your registration as a user.
                </Text>

                {/* Retention of Personal Data */}
                <Text fontSize={'1.2rem'} mt={2} fontWeight={'600'}>
                    Retention of Your Personal Data
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    The Company will retain your Personal Data only for as long as necessary for the purposes set out in this Privacy Policy.
                </Text>

                {/* Transfer of Your Personal Data */}
                <Text fontSize={'1.2rem'} mt={2} fontWeight={'600'}>
                    Transfer of Your Personal Data
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    Your information may be transferred to — and maintained on — computers located outside of your jurisdiction.
                </Text>

                {/* Delete Your Personal Data */}
                <Text fontSize={'1.2rem'} mt={2} fontWeight={'600'}>
                    Delete Your Personal Data
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    You have the right to delete or request assistance in deleting the Personal Data that we have collected about you.
                </Text>

                {/* Security of Your Personal Data */}
                <Text fontSize={'1.2rem'} mt={2} fontWeight={'600'}>
                    Security of Your Personal Data
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    The security of your Personal Data is important to us, but no method of transmission over the Internet is 100% secure.
                </Text>

                {/* Children's Privacy */}
                <Text fontSize={'1.2rem'} mt={2} fontWeight={'600'}>
                    Children’s Privacy
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    Our Service does not address anyone under the age of 13. We do not knowingly collect personal information from anyone under 13.
                </Text>

                {/* Links to Other Websites */}
                <Text fontSize={'1.2rem'} mt={2} fontWeight={'600'}>
                    Links to Other Websites
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    Our Service may contain links to other websites not operated by us. We strongly advise you to review the Privacy Policy of every site you visit.
                </Text>

                {/* Changes to this Privacy Policy */}
                <Text fontSize={'1.2rem'} mt={2} fontWeight={'600'}>
                    Changes to this Privacy Policy
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                </Text>

                {/* Contact Us */}
                <Text fontSize={'1.2rem'} mt={2} fontWeight={'600'}>
                    Contact Us
                </Text>
                <Text fontSize={'1.1rem'} mt={2} fontWeight={'400'} color={'grey'}>
                    If you have any questions about this Privacy Policy, you can contact us:
                </Text>
                <ul style={{ color: 'grey', fontWeight: "400", fontSize: '1.1rem',marginBottom:'10px'}}>
                    <li>By email: care@aahanainteriors.in</li>
                    <li>By visiting this page on our website: www.aahanainteriors.in</li>
                    <li>By phone number: 9650733838</li>
                </ul>
            </VStack>
        </>
    )
}

export default Privacy_Policy