import { Avatar, Box, Button, Grid, Heading, HStack, IconButton, Tab, Table, TableContainer, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import './AdminUser.css'
import SideBar from '../AdminSideBar/AdminSideBar'
import Header from '../AdminHeader/AdminHeader'
import user1 from '../AdminUser/s-1.jpg'
import user2 from '../AdminUser/s-2.jpg'
import user3 from '../AdminUser/s-3.jpg'
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AdminChangeUserRole, AdminDeleteUser, AdminLoadAllUser } from '../../../Redux/Action/UserActions'
import { RiDeleteBin7Fill } from 'react-icons/ri'

const AdminUser = () => {

    const userDetails = [
        {
            name: 'bed',
            proId: '12298476458',
            email: 's@gmail.com',
            phone:'98475212',
            action: 'fcd'
        },
       

    ]

    const dispatch = useDispatch();
    const { allUser, loading, isAuthenticated } = useSelector(
		state => state.userContainer
	);
    const deleteHandler =async (id) => {
        await dispatch(AdminDeleteUser(id))
        dispatch(AdminLoadAllUser())
        
    }
    const updateRoleHandler =async (id) => {
        const role='admin';
        await dispatch(AdminChangeUserRole(id,role))
        dispatch(AdminLoadAllUser())

    }

    useEffect(()=>{
        dispatch(AdminLoadAllUser())
    },[])

    return (
        <>
            <Header />
            <Grid
                w={"100%"}
                height={"100vh"}
                bgColor={"rgb(247, 247, 247)"}
                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"2rem"}
            >
                <SideBar />
                <Box  overflowY={"auto"} p={3} w={'100%'}>
                    <Heading children={'User Details'} size={'lg'} m={[1,5]} textAlign={['center','left']} textTransform={'uppercase'} />

                    <TableContainer p={5} w={'100%'}>
                        <Table size='sm' w={'100%'} fontSize={['lg', 'md', 'sm']}>
                            <Thead w={'100%'} >
                                <Tr w={'100%'}>
                                    <Th bg='#bfa888' color='white' p={3}>S.No</Th>
                                    <Th bg='#bfa888' color='white'>Name</Th>
                                    <Th bg='#bfa888' color='white'>Email</Th>
                                    <Th bg='#bfa888' color='white'>Phone</Th>
                                    <Th bg='#bfa888' color='white'>Role</Th>
                                    <Th bg='#bfa888' color='white' textAlign={'center'}>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {allUser && allUser.map((item, i) => (
                                    <Tr key={i}>
                                        <Td>{i+1}</Td>
                                        <Td>{item._id}</Td>
                                        <Td>{item.name}</Td>
                                        <Td>{item.email}</Td>
                                        <Td>{item.role}</Td>
                                        {/* <Td>
                                            #{item.subscription.status === 'active' ? 'Active' : 'Not Active'}
                                            active
                                        </Td> */}
                                        <Td isNumeric>
                                            <HStack justifyContent={'flex-end'}>
                                                <Button variant={'outline'} color={'purple.400'} onClick={() => updateRoleHandler(item._id)}>
                                                    Change Role
                                                </Button>
                                                <Button color={'purple.600'} onClick={() => deleteHandler(item._id)} >
                                                    <RiDeleteBin7Fill />
                                                </Button>
                                            </HStack>
                                        </Td>

                                    </Tr>
                                ))}

                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid></>
    )
}

export default AdminUser