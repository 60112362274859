import React from 'react'
import SideBar from '../AdminSideBar/AdminSideBar'
import { Avatar, Box, Grid, Heading, HStack, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Header from '../AdminHeader/AdminHeader'
import { Link } from 'react-router-dom'
import { MdDelete } from 'react-icons/md'
import user1 from '../AdminProfile/s-1.jpg'
import user2 from '../AdminProfile/s-2.jpg'
import user3 from '../AdminProfile/s-3.jpg'
import user4 from '../AdminProfile/s-4.jpg'
const userDetails = [
    {
        name: 'Robin zudu',
        phone: '123456789',
        email: 'xyz@gmail.com',
        avatar: user1,
        Order: 'bed ',
        Price: '1234',
        active: 'jds'
    },
  

   

]
const AdminProfile = () => {
    return (
        <>
            <Header />
            <Grid
                w={"100%"}
                height={"100vh"}
                bgColor={"rgb(247, 247, 247)"}
                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"2rem"}
            >
                <SideBar />
                <Box  overflowY={"auto"} p={3}>
                    <Heading children={'Admin Profile'} size={'lg'} m={[1,5]} textAlign={['center','left']} textTransform={'uppercase'} />
                    <TableContainer p={5} w={'100%'}>
                        <Table size='sm' w={'100%'} fontSize={['lg', 'md', 'sm']}>
                            <Thead w={'100%'} >
                                <Tr w={'100%'}>
                                    <Th bg='#bfa888' color='white' p={3}>S.No</Th>
                                    <Th bg='#bfa888' color='white'>Profile</Th>
                                    <Th bg='#bfa888' color='white'>Name</Th>
                                    <Th bg='#bfa888' color='white'>Phone</Th>
                                    <Th bg='#bfa888' color='white'>Email</Th>
                                    <Th bg='#bfa888' color='white'>Order</Th>
                                    <Th bg='#bfa888' color='white'>Price</Th>
                                    <Th bg='#bfa888' color='white'>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {userDetails && userDetails.map((item,i) => (
                                    <Tr key={i}>
                                        <Td>{i+1}</Td>
                                        <Link to={'/admin/user/profile'}>
                                            <Td>
                                                <Avatar name='Dan Abrahmov' src={item.avatar} />
                                            </Td>
                                        </Link>
                                        <Td>{item.name}</Td>
                                        <Td>{item.phone}</Td>
                                        <Td>{item.email}</Td>
                                        <Td>{item.Order}</Td>
                                        <Td>{item.Price}</Td>
                                        <Td>
                                            <HStack width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                <IconButton size={'xs'}
                                                    variant='transparent'
                                                    colorScheme='red'
                                                    _hover={{ background: 'white', color: 'red' }}
                                                    icon={<MdDelete size={'xs'} />}
                                                />
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}

                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </>

    )
}

export default AdminProfile