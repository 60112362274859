import { Avatar, Box, Button, Card, CardBody, Grid, HStack, Heading, IconButton, Image, Input, InputGroup, InputLeftElement, Progress, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import SideBar from "../AdminSideBar/AdminSideBar";
import Header from "../AdminHeader/AdminHeader";
import { IoIosTrendingUp } from "react-icons/io";
import { IoMdTrendingDown } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { IoIosPeople } from 'react-icons/io'
import { RiDeleteBin7Fill, RiShoppingBag3Fill } from 'react-icons/ri'
import pic1 from '../Dashboard/banner-2.png'
import pic2 from '../Dashboard/banner-3.png'
import pic3 from '../Dashboard/banner-4.png'
import pic from '../Dashboard/banner-5.png'
import ss from '../Dashboard/banner.png'
import './Dashboard.css'
import { BarChart, DoughnutChart } from "../Charts/Chart";
import { BiMaleFemale } from "react-icons/bi";
import DashboardTableTransaction from "../DashboardTable/DashboardTableTransaction";
import data from '../data.json'
import Cards from "./Cards";
import { useDispatch, useSelector } from "react-redux";
import { GrUpdate } from "react-icons/gr";
import { LoadUser } from "../../../Redux/Action/UserActions";
import { LoadAllProducts } from "../../../Redux/Action/ProductActions";
import { GetAllUserOrder } from "../../../Redux/Action/OrderActions";
import { AdminLoadAllPayment } from "../../../Redux/Action/PaymentActions";


 

const Dashboard = () => {
  const { allUser } = useSelector(
		state => state.userContainer
	);
  const { products } = useSelector(
    state => state.productContainer
  );

  const {adminOrder } = useSelector(
		state => state.orderContainer
	);

  const { payment, totalEarnings, payloading } = useSelector(
    state => state.paymentContainer
);

  const recentUser = [
    {
      // avatar: 'https://bit.ly/tioluwani-kolawole',
      name: 'Dr rahul kumar',
      email: 'rahul@gmail.com',
      phone: '1234567890'
    },
    {
      // avatar: 'https://bit.ly/tioluwani-kolawole',
      name: 'Dr rahul kumar',
      email: 'rahul@gmail.com',
      phone: '1234567890'
    },
    {
      // avatar: 'https://bit.ly/tioluwani-kolawole',
      name: 'Dr rahul kumar',
      email: 'rahul@gmail.com',
      phone: '1234567890'
    },

  ]

  const dispatch = useDispatch()

	// const navigate=useNavigate() 

	useEffect(() => {
		dispatch(LoadUser())
		dispatch(LoadAllProducts())
		dispatch(GetAllUserOrder())
    dispatch(AdminLoadAllPayment())

	},[])

  return (
    <>
      <Header />
      <Grid w={'100%'} height={'100vh'} bgColor={'rgb(247, 247, 247)'} gridTemplateColumns={{ base: '1fr', md: '1fr', lg: '1fr 4fr' }} gap={'2rem'} >
        <SideBar />
        <Box overflowY={'auto'} p={3} >

          <Heading children={'Dashboard'} size={'lg'} m={5} textAlign={'center'} textTransform={'uppercase'} />
          <HStack w={'100%'} alignItems={'center'} justifyContent={'center'} spacing={8} flexWrap={'wrap'}>
          

            <Cards
              title="Total Users"
              total={allUser?.length}
              bgGradient="linear-gradient(135deg, rgba(255, 165, 0, 0.89) 0%, rgba(255, 224, 178, 1) 100%)"
              className="circleWrapper"
            />
            <Cards
              title="Total Order"
              total={adminOrder?.length}
              bgGradient="linear-gradient(135deg, rgba(210, 180, 140, 0.89) 0%, rgba(255, 228, 196, 1) 100%)"
              className="circleWrapper blue"
            />
            <Cards
              title="Total Transaction"
              total={payment?.length}
              bgGradient="linear-gradient(135deg, rgba(76, 175, 80, 0.89) 0%, rgba(200, 255, 200, 1) 100%)"
              className="circleWrapper"
            />
            <Cards
              title="Total Product"
              total={products?.length}
              bgGradient=" linear-gradient(135deg, #ff9c1d 0%, #ff428d 100%)"
              className="circleWrapper"
            />

          </HStack>

          <Stack w={'95%'} m={'auto'} spacing={10} alignItems={'center'} justifyContent={'center'} mt={10} mb={5} direction={{ base: 'column', md: 'row' }}>
            {/* Grapgh here */}

            <Box w={{ base: '100%', md: '50%' }} boxShadow='lg' p='6' rounded='md' bg='white'>
              <Heading children={'User chart'} mb={10} fontSize={'2rem'} textTransform={'uppercase'} letterSpacing={'2px'} />
              <BarChart
                data_1={[50, 100, 150, 200, 250, 300]}
                data_2={[350, 400, 450, 500, 550, 600]}
                title_1={'Reviews'}
                title_2={'Users'}
                bgColor_1={'red'}
                bgColor_2={'green'}
              />
            </Box>


            <Box w={{ base: '100%', md: '40%' }} >
              <Heading children={'Resent Users'} fontSize={'1.5rem'} />
              {
                allUser && allUser.slice(-3).map((item) => (

                  <HStack w={'100%'} bgColor={'#8080801c'} p={3} alignItems={'center'} mt={4} boxShadow='sm' rounded='sm'>
                    <Avatar name={item.name} src={item.avatar.url} size={'lg'} />
                    <VStack alignItems={'flex-start'} ml={3} spacing={0}>
                      <Heading children={item.name} fontSize={'1rem'} />
                      <Text children={item.email} />
                      <Text children={item.phone} />
                    </VStack>
                  </HStack>
                ))
              }

            </Box>
          </Stack>



          <Stack w={{ base: '90%', md: '90%' }} m={'auto'} spacing={10} mt={8} mb={5} direction={{ base: 'column', md: 'row' }}>
            {/* <Box w={{ base: '100%', md: '100%', lg: '40%' }} boxShadow='lg' p='2' rounded='lg' bg='white' pos={'relative'}   >

              <Heading textAlign={'center'} mb={3} fontSize={'2rem'} textTransform={'uppercase'} letterSpacing={'2px'} children={'User Ratio'} />

               <DoughnutChart
                labels={["Female", "Male"]}
                data={[12, 19]}
                backgroundColor={["hsl(340,82%,56%)", "rgba(53,162,235,0.8)"]} />


            </Box> */}

            <Box w={{ base: '100%', md: '100%', lg: '100%' }}>
              {/* <Heading children={'Resent Order'} fontSize={'1.5rem'} mb={5} /> */}

              {/* <DashboardTableTransaction data={adminOrder} /> */}
              <Heading children='Resent Orders' textAlign={['center', 'left']} textTransform={'uppercase'} />
      <TableContainer w={['100vw', 'full']} >
        <Table variant='simple'  size={'lg'}>
          <TableCaption>Aavaiable Order in the Data Base</TableCaption>
          <Thead>
            <Tr>
              <Th>Status</Th>
              <Th>User</Th>
              {/* <Th >Total Price</Th> */}
              {/* <Th >shipping Price</Th> */}
              {/* <Th >taxPrice</Th> */}
              <Th >Product</Th>
              <Th >Action</Th> 
            </Tr>
          </Thead>
          <Tbody>
            {
              adminOrder && adminOrder.slice(-5).map((item, i) => (
                <Tr key={i}>
                    <Td >
                      {item.orderStatus}
                      <IconButton colorScheme={'orange'} variant={'ghost'} color={'white'}  >
                              <GrUpdate  /> 
                      </IconButton>
                       
                    </Td>
                    <Td >{item?.user?.name}</Td>
                    {/* <Td >{item.totalPrice}</Td> */}
                    {/* <Td >{item.shippingPrice}</Td> */}
                    {/* <Td >{item.taxPrice}</Td> */}

                  {/* product nested table */}
                    <Td  w={'100%'}>                      
                      <Table variant='striped' size={'lg'}>
                        <Thead>
                          <Tr>
                            {/* <Th>poster</Th> */}
                            <Th>name</Th>
                            <Th>items price</Th>
                            {/* <Th >Product Id</Th> */}
                            <Th>price</Th>
                            <Th >qty</Th> 
                          </Tr>
                        </Thead>
                      </Table>
                      <Tbody w={'100%'}>
                        {
                          item.orderProduct && item.orderProduct.map((pro)=>(
                              <Tr w={'100%'} >
                                  {/* <Td  w={'100%'}>
                                      <Image w={'100%'} src={pro.image} alt='poster' aspectRatio={'1/1'} />
                                  </Td> */}
                                  <Td >{pro.name}</Td>
                                  <Td >{pro.quantity*pro.price}</Td>
                                  {/* <Td >{pro.product}</Td> */}
                                  <Td >{pro.price}</Td>
                                  <Td >{pro.quantity}</Td>
                              </Tr>
                          ))
                        }
                      </Tbody>                        
                    </Td>

                    <Td isNumeric>
                        <HStack justifyContent={'flex-end'}>
                          {/* <Button
                            variant={'outline'}
                            color={'purple.400'}
                            onClick={() => ProductDetailHandler(item._id)}
                            isLoading={loading}
                            >
                            View Product
                          </Button> */}
                          <Button  color={'purple.600'}  >
                            <RiDeleteBin7Fill />
                          </Button>
                        </HStack>
                    </Td>

                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </TableContainer>
            </Box>
          </Stack>
        </Box>
      </Grid>
    </>
  );
};


export default Dashboard;
