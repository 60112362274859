import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Heading, HStack, Image, Input, useDisclosure, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import './style.css'
import { AiFillFileText } from 'react-icons/ai'
import { HiMenuAlt4 } from 'react-icons/hi'
import { IoIosPeople } from 'react-icons/io'
import { FaRegUserCircle, FaUser } from "react-icons/fa";
import { RiDashboardFill, RiShoppingBag3Fill } from 'react-icons/ri'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FaRegUser } from "react-icons/fa";
import logo from '../AdminHeader/Logo1.png'
import { RiMenu3Line } from "react-icons/ri";
import { IoMdLogOut } from "react-icons/io";
import { MdFormatListNumbered } from 'react-icons/md'
import { FaProductHunt } from "react-icons/fa";
import { FaBitbucket } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { FaGg } from "react-icons/fa";
import { FaBloggerB } from "react-icons/fa";
const AdminHeader = () => {

    const location = useLocation()
	const navigate = useNavigate()

    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <HStack w={'100%'} p={4} alignItems={'center'} justifyContent={{ base: 'space-between', md: 'space-between' }} display={{ base: 'flex', md: 'flex', lg: 'none' }}>

            <Button colorScheme='teal' variant='whatsapp' onClick={onOpen}>
                <RiMenu3Line size={'xs'} />
            </Button>
            <VStack  >
                <Image cursor={'pointer'} onClick={()=>navigate('/')} src={logo} alt="logo" width={'80%'} />
            </VStack>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent >
                    <DrawerCloseButton />
                    <DrawerHeader>Welcome to Aahana</DrawerHeader>

                    <DrawerBody>
                        <VStack w={'100%'} alignItems={'flex-start'}>
                            <SideBarItem url={'/admin/dashboard'} Icon={RiDashboardFill} location={location} text={'Dashboard'} />
                            <SideBarItem url={'/admin/user'} Icon={FaRegUser} location={location} text={'User'} />
                            <SideBarItem url={'/admin/product'} Icon={FaProductHunt} location={location} text={'Product'} />
                            <SideBarItem url={'/admin/order'} Icon={FaBitbucket} location={location} text={'Order'} />
                            {/* <SideBarItem url={'/admin/inventory'} Icon={MdFormatListNumbered} location={location} text={'Inventory'} /> */}
                            <SideBarItem url={'/admin/transaction'} Icon={GrTransaction} location={location} text={'Transaction'} />
                            {/* <SideBarItem url={'/admin/couponcode'} Icon={FaGg} location={location} text={'Coupon code'} /> */}
                            <SideBarItem url={'/admin/profile'} Icon={FaRegUserCircle} location={location} text={'Admin Profile'} />
                            {/* <SideBarItem url={'/admin/blog'} Icon={FaBloggerB} location={location} text={'Admin Blog'} /> */}
                        </VStack>
                    </DrawerBody>

                    <Box w={'85%'} m={'20px'}>
                        {/* <div className="logoutWrapper">
                            <div className="logoutBox"> */}
                        <Button w={'100%'} rightIcon={<IoMdLogOut />} children={'Logout'} colorScheme={'orange'} borderRadius={'8px'} size={['sm', 'md']} variant={'solid'} />
                        {/* </div>
                        </div> */}
                    </Box>
                </DrawerContent>
            </Drawer>

            <Box className='scrollBar' display={{ base: 'none', md: 'none', lg: 'block' }} border={'1px solid gold'} w={'100%'} bgColor={'white'} p={'1rem'} zIndex={'10'} overflowY={'auto'} __css={{ WebkitScrollSnapType: 'none' }}>
                <Heading size={'lg'} as={'h2'} children={'Dashboard'} />
                <Box w={'100%'} m={'1rem 0'} border={'1px solid blue'}>
                    {/* <Heading fontWeight={'500'} letterSpacing={'5px'} border={'1px solid red'} m={'1rem 0'} size={'md'}  as={'h5'} children={'Dashboard'} /> */}
                    <VStack w={'100%'} border={'1px solid red'} alignItems={'flex-start'} >
                        <SideBarItem url={'/admin/dashboard'} Icon={RiDashboardFill} location={location} text={'Dashboard'} />
                        <SideBarItem url={'/admin/user'} Icon={FaRegUser} location={location} text={'User'} />
                        <SideBarItem url={'/admin/product'} Icon={FaProductHunt} location={location} text={'Product'} />
                        <SideBarItem url={'/admin/order'} Icon={FaBitbucket} location={location} text={'Order'} />
                        <SideBarItem url={'/admin/inventory'} Icon={MdFormatListNumbered} location={location} text={'Inventory'} />
                        <SideBarItem url={'/admin/transaction'} Icon={GrTransaction} location={location} text={'Transaction'} />
                        {/* <SideBarItem url={'/admin/couponcode'} Icon={FaGg} location={location} text={'Coupon code'} /> */}
                        <SideBarItem url={'/admin/profile'} Icon={FaRegUserCircle} location={location} text={'Admin Profile'} />
                        {/* <SideBarItem url={'/admin/blog'} Icon={FaBloggerB} location={location} text={'Admin Blog'} /> */}
                    </VStack>
                    <Button w={'100%'} children={'Logout'} colorScheme='orange' fontSize={'1.2rem'} mt={'8rem'} borderRadius={'none'} />
                </Box>

            </Box>
        </HStack>
    )
}

export default AdminHeader

const SideBarItem = ({ url, Icon, location, text }) => {
    return (
        <Link style={{ width: '100%' }} placeSelf={'stretch'} to={url} >
            <Button style={{ width: '100%', alignContent: 'flex-start', justifyContent: 'flex-start' }} w={'100%'} size={'lg'} variant='ghost' colorScheme={location.pathname.includes(url) ? 'orange' : 'black'} leftIcon={<Icon />} children={text} />
        </Link>
    )
}