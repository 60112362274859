import React from 'react';
import { Box, Container, Heading, Text, VStack, List, ListItem } from '@chakra-ui/react';

const RefundPolicy = () => {
  return (
    <Container maxW="container.xl" py={8}>
      <Box>
        <Heading as="h1" fontSize={'2.2rem'} mb={4}>
          Refund Policy
        </Heading>
        <Text fontSize="lg" mb={6} color={'grey'}>
          Thank you for shopping with Aahana. If you are not entirely satisfied with your purchase, we're here to help.
        </Text>

        <VStack spacing={2} align="start">
          <Text fontWeight="bold" fontSize={'1.5rem'}>Returns</Text>
          <Text color={'grey'} fontSize={'1.1rem'}>
            You have 30 days to return an item from the date you received it. To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
          </Text>

          <Text fontWeight="bold" fontSize={'1.5rem'}>Refunds</Text>
          <Text color={'grey'} fontSize={'1.1rem'}>
            Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you of the status of your refund after inspecting the item. If your return is approved, we will initiate a refund to your original method of payment.
          </Text>

          <Text fontWeight="bold"fontSize={'1.5rem'} >Shipping</Text>
          <Text color={'grey'} fontSize={'1.1rem'}>
            You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable.
          </Text>

          <Text fontWeight="bold" fontSize={'1.5rem'}>Exchanges</Text>
          <Text color={'grey'} fontSize={'1.1rem'}>
            If you need to exchange an item for the same item, send us an email at care@aahanainteriors.in and send your item to: [Your Return Address].
          </Text>

          <Text fontWeight="bold"fontSize={'1.5rem'}>Contact Us</Text>
          <Text color={'grey'} fontSize={'1.1rem'}>
            If you have any questions about our refund policy, please contact us at:
          </Text>
          <List spacing={2} color={'grey'} fontSize={'1.1rem'}>
            <ListItem>Email: care@aahanainteriors.in</ListItem>
            <ListItem>Phone: 9650733838</ListItem>
          </List>
        </VStack>
      </Box>
    </Container>
  );
};

export default RefundPolicy;
