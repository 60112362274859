import { Box, Heading, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import terms from '../TermsAndConditions/terms.png'
const TermsAndConditions = () => {
    return (
        <>
            {/* <Box w={"100%"}  >
               
               <Image src={terms} alt={'privacy'} w={'100%'}  objectFit={'cover'} />

           </Box> */}
            <VStack lineHeight={1.2} w={'90%'} m={'auto'} p={'30px 0px'} alignItems={'flex-start'}>
                <Heading fontSize={'1.2rem'} children={'Welcome to Aahana! These Terms and Conditions outline the rules for using our eCommerce services. By accessing or using our website, you agree to comply with these terms.'} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Definitions'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– “Service” refers to the eCommerce services provided by our website.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– “User” refers to any individual accessing or using our services.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– “Vendor” refers to the sellers or service providers listed on our platform.'} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Usage Rights'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– The content on our website is for personal use only.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– You may not distribute, modify, or publish any content without prior written permission.'} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Accuracy and Limitations'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– Our services are based on available information and should not be viewed as guaranteed predictions or advice.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– While we strive for accuracy, we do not guarantee the completeness or reliability of our services.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– Our offerings should not replace professional advice in critical areas like health, finance, or legal matters.'} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Personal Responsibility'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– You use our services at your own risk. We are not liable for any decisions made based on the information provided.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– We encourage you to consult professionals for specific advice when necessary.'} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Privacy and Confidentiality'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– We respect your privacy and handle your information in accordance with our Privacy Policy.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– We strive to keep your information confidential but cannot guarantee absolute security.'} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Terms of Service'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– We grant you a limited, revocable, non-exclusive, and non-transferable right to access our services.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– Our courses and offerings may change periodically without prior notice.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– You are responsible for any technical requirements needed to access our services and assume all risks associated with data loss or damage.'} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Online Payment Terms'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– All transactions are processed through a secure electronic payment gateway. You agree to use it responsibly.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– You are at risk for any issues such as password misuse or online fraud. We disclaim all liability for such occurrences.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– We may impose limits on transactions to mitigate risks, which you agree to follow.'} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Termination and Changes'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– We reserve the right to suspend or terminate our services at any time without prior notice.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– These Terms and Conditions may be updated periodically, and it is your responsibility to review them regularly.'} />

                <Text mt={4} fontSize={'1.2rem'} fontWeight={'600'} children={'Governing Law'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– These Terms shall be governed by the laws of India.'} />
                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'– Any disputes arising will be subject to the exclusive jurisdiction of Indian courts.'} />

                <Text fontSize={'1.1rem'} fontWeight={'400'} color={'grey'} children={'If you have any questions about these Terms and Conditions, please contact us.'} />
            </VStack>

        </>
    )
}

export default TermsAndConditions
