import React, { useState } from 'react'
import AdminHeader from '../../AdminHeader/AdminHeader'
import { Box, Button, FormControl, Grid, Heading, Input, Select, VStack } from '@chakra-ui/react'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AdminCreateProduct, LoadAllProducts } from '../../../../Redux/Action/ProductActions'


const AdminAddNewproduct = () => {
    const [name, setname] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [stock, setStock] = useState('')
    const [category, setCategory] = useState('')

    const [imgpre, setImgpre] = useState('')
    const [createdBy, setCreatedBy] = useState('')
    const [productImages, setProductImages] = useState({})
    // console.log(productImages)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { loading } = useSelector(
        state => state.productContainer
      );


    const categories = ['Bedroom', 'Dining', 'Kitchen']


    const handleImages = (e, i) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            if (reader.readyState === 2) {
                if (i === 1) {
                    setProductImages({ ...productImages, image1: reader.result })
                }
                if (i === 2) {
                    setProductImages({ ...productImages, image2: reader.result })
                }
                if (i === 3) {
                    setProductImages({ ...productImages, image3: reader.result })
                }
                if (i === 4) {
                    setProductImages({ ...productImages, image4: reader.result })
                }
                if (i === 5) {
                    setProductImages({ ...productImages, image5: reader.result })
                }
            }

        }
    }

    const createCourseHandler = async (e) => {
        e.preventDefault()
        // const formdata = new FormData()
        // formdata.append('name', name)
        // formdata.append('description', description)
        // formdata.append('createdBy', createdBy)
        // formdata.append('category', category)
        // formdata.append('file', img)
        await dispatch(AdminCreateProduct(name, description, category, price, stock, productImages))
		await dispatch(LoadAllProducts())

        navigate('/admin/product')
    }
    return (
        <>
            <AdminHeader />
            <Grid
                w={"100%"}
                height={"100vh"}
                bgColor={"rgb(247, 247, 247)"}
                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"2rem"}
            >
                <AdminSideBar />
                <Box overflowY={"auto"} p={3}>
                    <Heading children={'Create new product'} size={'lg'} m={5} textAlign={'center'} textTransform={'uppercase'} />

                    <form onSubmit={createCourseHandler} >
        
                        <VStack w={'80%'} m={'auto'} spacing={'8'} >
                            <Input border={'1px solid grey'}
                                value={name} placeholder='enter name' type='text'
                                onChange={(e) => setname(e.target.value)} required
                                focusBorderColor='yellow.500'
                            />
                            <Input  border={'1px solid grey'}
                                value={description} placeholder='enter description' type='text'
                                onChange={(e) => setDescription(e.target.value)} required
                                focusBorderColor='yellow.500'
                            />
                            <Input  border={'1px solid grey'}
                                value={price} placeholder='enter price' type='number' required
                                onChange={(e) => setPrice(e.target.value)}
                                focusBorderColor='yellow.500'
                            />
                            <Input  border={'1px solid grey'}
                                value={stock} placeholder='enter stock' type='number' required
                                onChange={(e) => setStock(e.target.value)}
                                focusBorderColor='yellow.500'
                            />
                            <Select  border={'1px solid grey'}
                                value={category}
                                focusBorderColor='yellow.500'
                                onChange={(e) => setCategory(e.target.value)} required
                            >
                                <option >{'select category'}</option>
                                {
                                    categories.map((item) => (
                                        <option key={item} value={item}>{item}</option>
                                    ))
                                }
                            </Select>
                            <Input  border={'1px solid grey'}
                                required type='file' focusBorderColor='yellow.500' accept='image/*' 
                                onChange={(e) => handleImages(e, 1)}
                            />
                            <Input  border={'1px solid grey'}
                                required type='file' focusBorderColor='yellow.500' accept='image/*'
                                onChange={(e) => handleImages(e, 2)}
                            />
                            <Input  border={'1px solid grey'}
                                required type='file' focusBorderColor='yellow.500' accept='image/*'
                                onChange={(e) => handleImages(e, 3)}
                            />
                            <Input  border={'1px solid grey'}
                                required type='file' focusBorderColor='yellow.500' accept='image/*'
                                onChange={(e) => handleImages(e, 4)}
                            />
                            <Input  border={'1px solid grey'}
                                required type='file' focusBorderColor='yellow.500' accept='image/*'
                                onChange={(e) => handleImages(e, 5)}
                            />

                            <Button isLoading={loading} w={'full'} colorScheme={'orange'} type='submit' >
                                Create
                            </Button>
                        </VStack>
                    </form>
                </Box>
            </Grid>
        </>
    )
}

export default AdminAddNewproduct