import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, HStack, Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AiFillFileText } from 'react-icons/ai'
// import { FaBasketShopping, FaUser } from 'react-icons/fa6'
import { FaBitbucket, FaBloggerB, FaGg, FaLock, FaProductHunt, FaRegUser, FaRegUserCircle } from "react-icons/fa";
import { HiMenuAlt4 } from 'react-icons/hi'
import { IoIosPeople } from 'react-icons/io'
import { RiDashboardFill, RiHome2Fill, RiHome2Line, RiShoppingBag3Fill } from 'react-icons/ri'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { IoSettings, IoNotifications } from "react-icons/io5";
import { FaBlogger } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import logo from '../AdminSideBar/Logo1.png'
import './style.css'
import { MdFormatListNumbered, MdVerifiedUser } from 'react-icons/md';
import { GrTransaction } from 'react-icons/gr';
import { LogoutUser } from '../../../Redux/Action/UserActions';
import { useDispatch, useSelector } from 'react-redux';


const AdminSideBar = () => {
  const location = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {  loading  } = useSelector(
		state => state.userContainer
	);

  const dispatch = useDispatch()
	const navigate = useNavigate()

  const LogoutHandler = async () => {
		await dispatch(LogoutUser());
		navigate('/')
	}
  return (
    <>
      {/* <Button zIndex={'20'} border={'1px solid gold'} pos={'absolute'} left={'5'} top={'5'} rounded={'lg'} colorScheme={'whatsapp'} display={{base:'block',md:'block',lg:'none'}} onClick={() => onOpen()}>
        <HiMenuAlt4 />
    </Button>
     
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
        >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create your account</DrawerHeader>

          <DrawerBody>
            <VStack w={'100%'} border={'1px solid red'} alignItems={'flex-start'} >
                <SideBarItem url={'/admin/daskboard'} Icon={RiDashboardFill} location={location} text={'Daskboard'} />
                <SideBarItem url={'/admin/product'} Icon={RiShoppingBag3Fill} location={location} text={'Product'} />
                <SideBarItem url={'/admin/customer'} Icon={IoIosPeople} location={location} text={'Customer'} />
                <SideBarItem url={'/admin/transaction'} Icon={AiFillFileText} location={location} text={'Transaction'} />
            </VStack>
          </DrawerBody>

        </DrawerContent>
      </Drawer> */}

      <Box borderRight={'1px solid grey'} className='scrollBar' display={{ base: 'none', md: 'none', lg: 'block' }} w={'100%'} p={'1rem'} zIndex={'10'} overflowY={'auto'} __css={{ WebkitScrollSnapType: 'none' }} >
        {/* <Heading size={'lg'} as={'h2'} children={'Dashboard'} /> */}
        <VStack>
          <Image cursor={'pointer'} onClick={()=>navigate('/')} src={logo} alt="logo" width={'100%'} />
        </VStack>
        <Box w={'100%'} m={'1rem 0'}>

          <VStack w={'100%'} alignItems={'flex-start'} >
            <SideBarItem url={'/'} Icon={RiHome2Line} location={location} text={'Home'} />
            <SideBarItem url={'/admin/dashboard'} Icon={RiDashboardFill} location={location} text={'Dashboard'} />
            <SideBarItem url={'/admin/user'} Icon={FaRegUser} location={location} text={'User'} />
            <SideBarItem url={'/admin/product'} Icon={FaProductHunt} location={location} text={'Product'} />
            <SideBarItem url={'/admin/order'} Icon={FaBitbucket} location={location} text={'Order'} />
            {/* <SideBarItem url={'/admin/inventory'} Icon={MdFormatListNumbered} location={location} text={'Inventory'} /> */}
            <SideBarItem url={'/admin/transaction'} Icon={GrTransaction} location={location} text={'Transaction'} />
            {/* <SideBarItem url={'/admin/couponcode'} Icon={FaGg} location={location} text={'Coupon code'} /> */}
            {/* <SideBarItem url={'/admin/profile'} Icon={FaRegUserCircle} location={location} text={'Admin Profile'} /> */}
            {/* <SideBarItem url={'/admin/blog'} Icon={FaBloggerB} location={location} text={'Admin Blog'} /> */}
          </VStack>
          <Box w={'100%'} mt={{base:'0',md:'6.5rem'}}>
            {/* <div className="logoutWrapper">
              <div className="logoutBox"> */}
            <Button isLoading={loading} onClick={() => LogoutHandler()} w={'100%'} rightIcon={<IoMdLogOut />} children={'Logout'} colorScheme='orange' borderRadius={'8px'} color={'white'} size={['sm', 'md']} variant={'solid'} />
            {/* </div>
            </div> */}
          </Box>
        </Box>

      </Box>
    </>
  )
}

export default AdminSideBar

const SideBarItem = ({ url, Icon, location, text }) => {
  return (
    <Link style={{ width: '100%',color:'black' }} placeSelf={'stretch'} to={url} >
      <Button style={{ width: '100%', alignContent: 'flex-start', justifyContent: 'flex-start' }} w={'100%'} size={'lg'} variant='ghost' colorScheme={location.pathname.includes(url) ?'orange' : 'black'} leftIcon={<Icon />} children={text} />
    </Link>
  )
}