import { Heading, HStack, ListItem, Stack, Text, UnorderedList, VStack } from '@chakra-ui/react'
import React from 'react'

const Shipping = () => {
    const shippingDetails = [
        {
            heading: 'Shipping Information',
            title: 'Delivery Charges:',
            des: 'Delivery charges may vary by seller based on the decor product category. For low-value items in our bedroom, kitchen, dining, and modern decor sections, a nominal fee may apply. Please check your order summary for specific charges. Items marked as "Aahana Plus" incur a delivery fee of ₹40 for orders under ₹500; orders of ₹500 or more are delivered free.'
        },
        {
            title: 'Estimated Delivery Time:',
            des: 'Delivery timelines depend on the seller, product availability, and your location. Business days exclude public holidays and Sundays. Enter your pincode on the product page for accurate delivery estimates for your chosen decor items.'
        },
        {
            title: 'Hidden Costs:',
            des: `There are no hidden charges. The price listed on the product page is all-inclusive for all categories, including bedroom, kitchen, dining, and modern decor. Delivery fees are applied according to the seller's policy.`
        },
        {
            title: 'Serviceability: ',
            des: `To check if a product from our bedroom, kitchen, dining, or modern decor categories can be delivered to your location, please enter your pincode on the product page. Serviceability may depend on seller discretion, legal restrictions, or the availability of reliable courier partners.`
        },
        {
            title: 'Cash on Delivery (CoD): ',
            des: `CoD availability is subject to the courier's ability to accept cash. Limits may apply based on location and order value. Use your pincode on the product page to confirm CoD options for your selected decor items.`
        },
        {
            title: 'Returns:',
            des: `To initiate a return for any decor product, please contact us for guidance on the process. Our logistics partner will handle pickups where possible; otherwise, items can be returned via a third-party courier at the seller’s expense.`
        },
        {
            title: 'Order Confirmation Issues:',
            des: 'If you receive a delivery confirmation but did not receive your order, please report the issue within 7 days for investigation.'
        },
    ]
    const productAvail = [
        {
            title: 'In Stock:',
            des: 'Available for immediate shipping.'
        },
        {
            title: 'Available:',
            des: 'Can be procured upon order.'
        },
        {
            title: 'Preorder:',
            des: 'Expected soon; shipping upon release.'
        },
        {
            title: 'Out of Stock:',
            des: `Currently unavailable; use 'Notify Me' for updates.`
        },
        {
            title: 'Imported:',
            des: 'Sourced from outside India; longer delivery times may apply.'
        },
        {
            title: 'Temporarily Unavailable:',
            des: ' Out of stock, but may return soon.'
        },
        {
            title: 'Permanently Discontinued/Out of Print:',
            des: 'No longer available for sale.'
        },
    ]
    return (
        <>
            <VStack spacing={2} w={'90%'} p={2} m={'auto'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                {shippingDetails && shippingDetails.map((item) => (
                    <>
                        <Heading fontSize={'1.5rem'} children={item.heading} />
                        <Text fontSize={'1rem'} color={'grey'}><span style={{ fontWeight: '600', fontSize: '1.2rem', color: 'black' }}> {item.title}  </span>{item.des}</Text>
                    </>
                ))}
                <Heading mt={5} fontSize={'1.5rem'} children={'Product Availability Tags:'} />
                {productAvail && productAvail.map((item) => (

                    <Stack w={'100%'} alignItems={'flex-start'} direction={{ base: 'column', md: 'row' }}>
                        <UnorderedList>
                            <ListItem fontSize={'1rem'} fontWeight={'600'}>{item.title}</ListItem>
                        </UnorderedList>
                        <Text fontSize={'1rem'} color={'grey'}>{item.des}</Text>
                    </Stack>
                ))}

                <Text mt={5} fontSize={'1rem'} color={'grey'}><span style={{ fontWeight: '600', fontSize: '1.2rem', color: 'black' }}>International Shipping:  </span> Currently, aahanainteriors.in does not deliver internationally. Purchases can be made from outside India, but the delivery address must be within India.</Text>
            </VStack>
        </>
    )
}

export default Shipping