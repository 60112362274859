import { Avatar, Box, Grid, Heading, HStack, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import AdminSideBar from '../AdminSideBar/AdminSideBar'
import AdminHeader from '../AdminHeader/AdminHeader'
import { Link } from 'react-router-dom'
import { MdDelete } from 'react-icons/md'
import user1 from '../AdminCoupon/s-1.jpg'
import user2 from '../AdminCoupon/s-2.jpg'
import user3 from '../AdminCoupon/s-3.jpg'
import user4 from '../AdminCoupon/s-4.jpg'
const userDetails = [
    {
        s_Num: '01',
        name: 'Robin zudu',
        phone: '123456789',
        email: 'xyz@gmail.com',
        avatar: user1,
        Invoice: 'hgdjfhcvds',
        Quatation: 'astrologer',
        active: 'jds'
    },
    {
        s_Num: '02',
        name: 'Jennifer wiz',
        phone: '123456789',
        email: 'xyz@gmail.com',
        avatar: user2,
        Invoice: 'hgdjfhcvds',
        Quatation: 'astrologer',
        active: 'jds'
    },
    {
        s_Num: '03',
        name: 'swai vivekanad',
        phone: '123456789',
        email: 'xyz@gmail.com',
        avatar: user3,
        Invoice: 'hgdjfhcvds',
        Quatation: 'astrologer',
        active: 'jds'
    },
    {
        s_Num: '03',
        name: 'swai vivekanad',
        phone: '123456789',
        email: 'xyz@gmail.com',
        avatar: user4,
        Invoice: 'hgdjfhcvds',
        Quatation: 'astrologer',
        active: 'jds'
    },

]
const AdminCoupon = () => {
    return (
        <>
            <AdminHeader />
            <Grid
                w={"100%"}
                height={"100vh"}
                bgColor={"rgb(247, 247, 247)"}
                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"2rem"}
            >
                <AdminSideBar />
                <Box border={"1px solid red"} overflowY={"auto"} p={3}>
                    <Heading children={'Coupon code'} size={'lg'} m={[1,5]} textAlign={['center','left']} textTransform={'uppercase'} />
                  
                </Box>
            </Grid>
        </>
    )
}

export default AdminCoupon