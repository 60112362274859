import { Box, Button, Container, Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { RiCheckDoubleFill } from 'react-icons/ri'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

const PaymentSuccess = () => {
    const navigate=useNavigate()


    const search = useSearchParams()[0]
    const refrenceid = search.get("refrence") 
    return (
        <Container h={'90vh'} p={'16'} >
            <Heading children='Your Payment has been Recevied' my={'8'} textAlign={'center'} />
            <VStack  alignItems={'center'} spacing={'4'} >
                <Box w={'full'} bg={'yellow.400'} p={'4'}  >
                    <Text color={'black'} children='Payment Success' />
                </Box>
                <Box p={'4'}  >
                    <VStack textAlign={'center'} px={'8'} mt={'4'} spacing={'8'} >
                        {/* <Text>
                            congratulation you are a pro memeber. you have success to premium content
                        </Text> */}
                        <Heading size={'2xl'} >
                            <RiCheckDoubleFill color='green' />
                        </Heading>
                    </VStack>
                </Box>

                <Link to={'/profile'}>
                    <Button variant={'outline'} colorScheme={'orange'} >Go to Profile</Button>
                </Link>

                <Heading size={'sm'} >
                    Reference : {refrenceid}
                    
                </Heading>
            </VStack>
        </Container>
    )
}

export default PaymentSuccess