import React, { useEffect } from 'react'
import { Avatar, Box, Button, Grid, Heading, HStack, IconButton, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import AdminSideBar from '../AdminSideBar/AdminSideBar'
import { MdDelete } from 'react-icons/md'
import user1 from '../AdminTransaction/s-1.jpg'
import user2 from '../AdminTransaction/s-2.jpg'
import user3 from '../AdminTransaction/s-3.jpg'
import user4 from '../AdminTransaction/s-4.jpg'
import AdminHeader from '../AdminHeader/AdminHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AdminDeletePaymentTransaction, AdminLoadAllPayment } from '../../../Redux/Action/PaymentActions'
const AdminTransaction = () => {
    const { payment, totalEarnings, payloading } = useSelector(
        state => state.paymentContainer
    );

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const deletePaymentHandler=async (TranId)=>{
       await dispatch(AdminDeletePaymentTransaction(TranId))
       await dispatch(AdminLoadAllPayment())

    }

    useEffect(()=>{
        dispatch(AdminLoadAllPayment())
    },[])

    return (
        <>
            <AdminHeader />
            <Grid
                w={"100%"}
                height={"100vh"}
                bgColor={"rgb(247, 247, 247)"}
                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"2rem"}
            >
                <AdminSideBar />
                <Box  overflowY={"auto"} p={3}>
                    <Heading children={'Total transaction'} size={'lg'} m={[1,5]} textAlign={['center','left']} textTransform={'uppercase'} />
                    <TableContainer p={5} w={'100%'}>
                        <Table size='sm' w={'100%'} fontSize={['lg', 'md', 'sm']}>
                            <Thead w={'100%'} >
                                <Tr w={'100%'}>
                                    <Th bg='#bfa888' color='white' p={3}>S.No</Th>
                                    <Th bg='#bfa888' color='white'>Name</Th>
                                    <Th bg='#bfa888' color='white'>Phone</Th>
                                    <Th bg='#bfa888' color='white'>Email</Th>
                                    <Th bg='#bfa888' color='white'>Date</Th>
                                    <Th bg='#bfa888' color='white'>Tran_Id</Th>
                                    {/* <Th bg='#bfa888' color='white'>Price</Th> */}
                                    <Th bg='#bfa888' color='white' textAlign={'center'}>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {payment && payment.map((item, i) => (
                                    <Tr key={i}>
                                        <Td>{i+1}</Td>
                                        <Td>{item.userName}</Td>
                                        <Td>{item.phone}</Td>
                                        <Td>{item.email}</Td>
                                        <Td>{item.createdAt.split("T")[0]}</Td>
                                        <Td>{item.razorpay_payment_id}</Td>
                                        {/* <Td>{item.Price}</Td> */}
                                        {/* <Td>{item.active}</Td> */}
                                        <Td>
                                            <HStack width={'100%'} alignItems={'center'} justifyContent={'center'}>
                                                {/* <IconButton size={'xs'}
                                                    variant='transparent'
                                                    colorScheme='red'
                                                    _hover={{ background: 'white', color: 'red' }}
                                                    icon={<MdDelete size={'xs'} />}
                                                /> */}
                                                  <Button onClick={()=>deletePaymentHandler(item._id)} size={'xs'} colorScheme='red' variant={'solid'}>delete</Button>
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}

                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </>
    )
}

export default AdminTransaction