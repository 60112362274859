import axios from 'axios';
import { server } from '../../index';
import toast from 'react-hot-toast';

export const GetPaymentKey = () => async dispatch => {
	try {
		dispatch({ type: 'GetPaymentKeyRequest' });

		const { data } = await axios.get(
			`${server}/payment/key`,
			// { courseId },
			{
				headers: {
					'Content-Type': 'application/json',
				},
				withCredentials: true,
			}
		);
        console.log(data.key)

		dispatch({ type: 'GetPaymentKeySuccess', payload: data });

		// console.log(data);
		// toast.success(data.message);
	} catch (error) {
		dispatch({ type: 'GetPaymentKeyFail', payload: error });
		// toast.error(error.message);
	}
};

export const PaymentProcess = price => async dispatch => {
	try {
		dispatch({ type: 'PaymentProcessRequest' });

		const { data } = await axios.post(
			`${server}/payment/process`,
			{ price },
			{
				headers: {
					'Content-Type': 'application/json',
				},
				withCredentials: true,
			}
		);

		dispatch({ type: 'PaymentProcessSuccess', payload: data });

		// toast.success(data.message);
	} catch (error) {
		dispatch({ type: 'PaymentProcessFail', payload: error });

		// toast.error(error.response.data.message);
	}
};

export const AdminLoadAllPayment = () => async dispatch => {
	try {
		dispatch({ type: 'AdminLoadAllPaymentRequest' });

		const { data } = await axios.get(`${server}/payment/get/all`, {
			headers: {
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		});

		dispatch({ type: 'AdminLoadAllPaymentSuccess', payload: data });

		// toast.success(data.message);
	} catch (error) {
		dispatch({ type: 'AdminLoadAllPaymentFail', payload: error });

		// toast.error(error.response.data.message);
	}
};

export const AdminDeletePaymentTransaction = TranId => async dispatch => {
	try {
		dispatch({ type: 'AdminDeletePaymentRequest' });

		const { data } = await axios.delete(`${server}/payment/delete/${TranId}`, {
			headers: {
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		});

		dispatch({ type: 'AdminDeletePaymentSuccess', payload: data });

		toast.success(data.message);
	} catch (error) {
		dispatch({ type: 'AdminDeletePaymentFail', payload: error });

		toast.error(error.response.data.message);
	}
};
