import React from 'react'
import { Avatar, Box, Button, Grid, Heading, HStack, IconButton, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import AdminSideBar from '../AdminSideBar/AdminSideBar'
import AdminHeader from '../AdminHeader/AdminHeader'
import AdminProductCard from '../AdminProduct/AdminProductCard'
import { MdDelete } from 'react-icons/md'
import user1 from '../AdminProduct/s-1.jpg'
import user2 from '../AdminProduct/s-2.jpg'
import user3 from '../AdminProduct/s-3.jpg'
import user4 from '../AdminProduct/s-4.jpg'
import { IoIosEye } from "react-icons/io";
import { FaPlusCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
const userDetails = [
    {
        banner: user1,
        productName: 'dinvbnvbing table',
        category: 'dinng',
        qty: '2',
        stock: '5',
        price: '1500',
    },
    {
        banner: user2,
        productName: 'dining table',
        category: 'dinng',
        qty: '2',
        stock: '5',
        price: '1500',
    },

]
const AdminProduct = () => {
    const { products, loading } = useSelector(
        state => state.productContainer
      );
 const navigate = useNavigate()
    return (
        <>
            <AdminHeader />
            <Grid
                w={"100%"}
                height={"100vh"}
                bgColor={"rgb(247, 247, 247)"}
                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"2rem"}
            >
                <AdminSideBar />
                <Box  overflowY={"auto"} p={3}>
                    <Heading children={'All Products'} size={'lg'} m={[1,5]} textAlign={['center','left']} textTransform={'uppercase'} />
                    {/* <Link to={'/admin/product/add/newproduct'}> */}
                        <Button m={'2rem 0'} onClick={()=>navigate('/admin/product/add/newproduct')} rightIcon={<FaPlusCircle />} colorScheme='orange' variant='outline' ml={[1,5]}>
                            Add New product
                        </Button>
                    {/* </Link> */}

                    <HStack w={'100%'} alignItems={'center'} justifyContent={'center'} flexWrap={'wrap'}>
                        {
                            products && products.map((item)=>(

                                <AdminProductCard image={item.productImages.image1.url} name={item.name} price={item.price} id={item._id} rating={item.rating} noOfReview={item.totalReviewOfUsersOnProduct.length}/>
                            ))
                        }
                    </HStack>
                </Box>
            </Grid>
        </>
    )
}

export default AdminProduct