import { Box, Card, CardBody, Heading, HStack, Text } from '@chakra-ui/react'
import React from 'react'

const Cards = ({ title, total, bgGradient, className}) => {
    const cardStyle = {
        background: bgGradient,
        padding: '15px',  
      };
    return (
        <>
            <Card boxShadow='xl' p='6' rounded='md' bg='white' maxW={{base:'300px',md:'250px',lg:"210px"}} h={'130px'} w={'100%'} className={className} style={cardStyle}>
                <CardBody w={'100%'}>
                    <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                        <Heading children={title} fontSize={'1rem'}  opacity={'.8'} textTransform={'capitalize'} />
                        <Box className="circle"  >
                        </Box>
                    </HStack>
                    <Text children={total} fontSize={'2rem'} fontWeight={'700'}  />
                </CardBody>
            </Card>
        </>
    )
}

export default Cards