import React, { Suspense, lazy, useEffect } from 'react';
import './App.css';
import { CircularProgress, Stack } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { LoadUser } from './Redux/Action/UserActions';
import { LoadAllProducts } from './Redux/Action/ProductActions';
import { GetAllUserOrder } from './Redux/Action/OrderActions';

import ForgetPassword from './components/User/ForgetPassword/ForgetPassword';
import ResetPassword from './components/User/ForgetPassword/ResetPassword';
import Privacy_Policy from './components/Privacy_Policy/Privacy_Policy';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import UserAddress from './components/User/UserAddress';
import RefundPolicy from './components/RefundPolicy/RefundPolicy';
import PaymentSuccess from './components/Payment/PaymentSuccess';
import Dashboard from './components/Admin2/Dashboard/Dashboard';
import AdminCoupon from './components/Admin2/AdminCoupon/AdminCoupon';
import AdminBlog from './components/Admin2/AdminBlog/AdminBlog';
import AdminInventory from './components/Admin2/AdminInventory/AdminInventory';
import AdminOrder from './components/Admin2/AdminOrder/AdminOrder';
import AdminProduct from './components/Admin2/AdminProduct/AdminProduct';
import AdminSingleProductDetails from './components/Admin2/AdminProduct/AdminSingleProduct/AdminSingleProductDetails';
import AdminTransaction from './components/Admin2/AdminTransaction/AdminTransaction';
import AdminUser from './components/Admin2/AdminUser/AdminUser';
import AdminProfile from './components/Admin2/AdminProfile/AdminProfile';
import AdminAddNewproduct from './components/Admin2/AdminProduct/AdminAddNewProduct/AdminAddNewproduct';
import Shipping from './components/Shipping/Shipping';
import { AdminLoadAllPayment } from './Redux/Action/PaymentActions';


const PlaceOrder = lazy(() => import('./components/CheckoutOrder/PlaceOrder'));
const Shop = lazy(() => import('./components/Shop/Shop'));

const Cart = lazy(() => import('./components/Cart/Cart'));
const Wishlist = lazy(() => import('./components/Wishlist/Wishlist'));
const CheckoutOrder = lazy(() => import('./components/CheckoutOrder/CheckoutOrder'));

const SingleProductDetails = lazy(() => import('./components/Products/SingleProduct/SingleProductDetails'));

const Login = lazy(() => import('./components/User/Login'));
const SignUp = lazy(() => import('./components/User/SignUp'));
const Profile = lazy(() => import('./components/User/Profile'));

const Header = lazy(() => import('./components/Header/Header'));
const Home = lazy(() => import('./components/Home/Home'));
const ContactUs = lazy(() => import('./components/Contact/ContactUs'));
const About = lazy(() => import('./components/About/About'));
const Footer = lazy(() => import('./components/Footer/Footer'));

function App() {
	const { loading, isAuthenticated } = useSelector(
		state => state.userContainer
	);

	const dispatch = useDispatch()

	// const navigate=useNavigate() 

	useEffect(() => {
		dispatch(LoadUser())
		dispatch(LoadAllProducts())
		dispatch(GetAllUserOrder())
    dispatch(AdminLoadAllPayment())

	}, [])
	return (
		<BrowserRouter>
			<Suspense fallback={
				<Stack w={'100%'} h={'100vh'} justifyContent={'center'} alignItems={'center'} >
					<CircularProgress thickness="4px" size={'20vmax'} isIndeterminate color="#bfa888" />
				</Stack>
			}
			>
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/login" element={<Login loading={loading} />} />
					<Route path="/signup" element={<SignUp loading={loading} />} />
					<Route path="/profile" element={isAuthenticated ? <Profile /> : <Login />} />
					<Route path="/contactus" element={<ContactUs />} />
					<Route path="/about" element={<About />} />
					<Route path="/single/product/Details" element={<SingleProductDetails />} />
					<Route path="/cart" element={<Cart />} />
					<Route path="/wishlist" element={<Wishlist />} />
					<Route path="/checkout" element={isAuthenticated ? <CheckoutOrder /> : <Login />} />
					<Route path="/placeorder" element={isAuthenticated ? <PlaceOrder /> : <Login />} />
					<Route path="/shop" element={<Shop />} />
					<Route path="/paymentsuccess" element={<PaymentSuccess />} />

					<Route path="/privacy" element={<Privacy_Policy />} />
					<Route path="/term&condition" element={<TermsAndConditions />} />
					<Route path="/refund" element={<RefundPolicy />} />
					{/* <Route path="/userAddress" element={<UserAddress />} /> */}

					<Route path="/forgetpassword" element={<ForgetPassword />} />
					<Route path="/api/v1/user/reset/password/:token" element={<ResetPassword />} />



					<Route path="/admin/dashboard" element={<Dashboard />} />
					<Route path="/admin/blog" element={<AdminBlog />} />
					<Route path="/admin/profile" element={<AdminProfile />} />
					<Route path="/admin/user" element={<AdminUser />} />
					<Route path="/admin/transaction" element={<AdminTransaction />} />
					<Route path="/admin/order" element={<AdminOrder />} />
					<Route path="/admin/product" element={<AdminProduct />} />
					<Route path="/admin/product/add/newproduct" element={<AdminAddNewproduct />} />
					<Route path="/admin/product/singleproduct" element={<AdminSingleProductDetails />} />
					<Route path="/admin/inventory" element={<AdminInventory />} />
					<Route path="/admin/couponcode" element={<AdminCoupon />} />

					<Route path="/shipping" element={<Shipping />} />

				</Routes>
				<Footer />
			</Suspense>
			<Toaster />
		</BrowserRouter>
	);
}

export default App;
