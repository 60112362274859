import { Box, Button, Grid, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import './AdminProductCard.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GetSignleProductsDetails } from '../../../Redux/Action/ProductActions'
import { addItemsToCart } from '../../../Redux/Action/CartActions'

const ProductCard = ({ noOfReview, rating, image, name, price, id }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const qty = 1;

    const { SignleProduct, ProductReviews, loading } = useSelector(
        state => state.productContainer
    );

    const addToCartHandler = async (id, quantity) => {
        await dispatch(addItemsToCart(id, quantity))
        // navigate('/cart') 
    }

    const ShowSingleProductDetails = async (id) => {
        await dispatch(GetSignleProductsDetails(id))
        navigate('/admin/product/singleproduct')
    }
    return (
        <>

            <VStack onClick={() => ShowSingleProductDetails(id)} id='CardContainer' borderRadius={'lg'} alignItems={'center'} border={'1px solid grey'} w={{ base: '350px', md: '300px', lg: '220px' }} m={{ base: '1px', md: '10px 5px' }} flexWrap={'wrap'}>
                <Box w={'100%'}  cursor={'pointer'} >
                    <Box className='container' borderRadius={'lg'} p={'.5rem'}>
                        <Image boxSize={'100%'} src={image} alt='ProductCard' aspectRatio={'3/3'} />
                    </Box>
                    <Heading m={'0 5px'} w={'90%'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'} fontWeight={'500'} fontSize={['xl']} color={'blackAlpha.800'} children={`${name}`} />
                    <HStack m={'0 5px'} w={'100%'} alignItems={'center'} >
                        <Text textDecorationLine={'line-through'} color={'blackAlpha.800'} children={`$${price}`} />
                        <Text textDecorationLine={'underline'} color={'#bfa888'} children={`$${price}`} />
                    </HStack>
                    <HStack m={'0 5px'} alignItems={'center'} justifyContent={'space-between'}>
                        <Text fontSize={'xl'} children={`${rating}★`} />
                        <Text color={'#bfa888'} children={`${noOfReview} Reviews`} />
                    </HStack>
                </Box>

            </VStack>

        </>
    )
}

export default ProductCard