import { Box, Button, Heading, Input, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { server } from '../../..'
import axios from 'axios'
import toast from 'react-hot-toast'
import './Style.css'

const ForgetPassword = () => {
  const [email, SetEmail] = useState('')

  const ForgetPasswordHandler = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post(
        `${server}/user/forget/password`,
        {
          email
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      toast.success(data.message);

    } catch (error) {
      toast.error(error.message);
    }

  }

  return (
    <VStack w={'100%'}  className='signUpBack' h={['70vh', '100vh']} spacing={'10'} justifyContent={'center'} alignItems={'center'}  >
      <VStack m={'1rem'}p={[4, 4]} w={['90%','50%']} className='inputForm' spacing={['2','10']} justifyContent={'center'} alignItems={'center'} >
        <Heading letterSpacing={'5px'} fontFamily={'Roboto'} children={'Re-Set your Password '} fontSize={'1.5rem'} textAlign={'center'} />
        <Box w={{ base: '100%', md: '80%' }} >
          <form onSubmit={ForgetPasswordHandler} style={{ padding: '1vmax' }} >
            <Input border={'1px solid grey'} _placeholder={{ color: '#6e4b2a' }} mb={'3vmax'} focusBorderColor={'orange.400'} required onChange={(e) => SetEmail(e.target.value)} value={email} fontFamily={'Roboto'} size={['sm', 'md']} fontSize={['2vmax', '1.8vmax', '1.2vmax']} type="text" placeholder="Enter Your Email" />
            <Button letterSpacing={'5px'} fontFamily={'Roboto'} type={'submit'} w={'100%'} children={'Forget Password'} variant={'solid'} colorScheme={'orange'} size={['sm', 'md']} />

          </form>
        </Box>
      </VStack>
    </VStack >
  )
}

export default ForgetPassword