import React, { useEffect, useState } from 'react';
import Header from '../../Header/Header'
import { Avatar, Box, Button, Grid, Heading, HStack, IconButton, Image, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import AdminSideBar from '../AdminSideBar/AdminSideBar'
import { MdDelete } from 'react-icons/md'
import user1 from '../AdminOrder/s-1.jpg'
import user2 from '../AdminOrder/s-2.jpg'
import user3 from '../AdminOrder/s-3.jpg'
import user4 from '../AdminOrder/s-4.jpg'
import AdminHeader from '../AdminHeader/AdminHeader'
import { IoMdEye } from 'react-icons/io';
import { FaEye } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AdminDeleteOrder, AdminGetAllOrders, AdminUpdateOrderStatus } from '../../../Redux/Action/OrderActions';
import { GetSignleProductsDetails } from '../../../Redux/Action/ProductActions';
import { RiDeleteBin7Fill } from 'react-icons/ri';
import { GrUpdate } from 'react-icons/gr';
const AdminOrder = () => {
    const userDetails = [
        {

           Id:'9530511',
           status:'processing',
           userId:'1234567',
           totalPrice:'3450',
           shippingPrice:'100',
           taxPrice:'25%',
           Product:'Dinning table',
           action:'dc'
        },
      

    ]
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [status,setStatus]=useState('')
    const [orderId,setOrderId]=useState('')

    const {adminOrder,loading } = useSelector(
		state => state.orderContainer
	);


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const deleteHandler =async (id) => {
     await dispatch(AdminDeleteOrder(id))
      dispatch(AdminGetAllOrders())
    }

    const updateOrderStatus=async (id)=>{     
      
      alert(`${id} ${status}`)

    await dispatch(AdminUpdateOrderStatus(id,status))
     dispatch(AdminGetAllOrders())

    onClose()

    }

    const ProductDetailHandler =async (id) => {
        await dispatch(GetSignleProductsDetails(id))
         navigate('/single/product/Details') 
      }
  
      const openStatusHandler=(id)=>{
        setOrderId(id)
        onOpen()
      }
  
      useEffect(()=>{
        dispatch(AdminGetAllOrders())
      },[dispatch])

    return (
        <>
            <AdminHeader />
            <Grid
                w={"100%"}
                height={"100vh"}
                bgColor={"rgb(247, 247, 247)"}
                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"2rem"}
            >
                <AdminSideBar />
               <Box p={['0', '16']} overflowX={'auto'}>
      <Heading children='All Orders' textAlign={['center', 'left']} textTransform={'uppercase'} />
      <TableContainer w={['100vw', 'full']} >
        <Table variant='simple'  size={'lg'}>
          <TableCaption>Aavaiable Order in the Data Base</TableCaption>
          <Thead>
            <Tr>
              <Th>Status</Th>
              <Th>User</Th>
              {/* <Th >Total Price</Th> */}
              {/* <Th >shipping Price</Th> */}
              {/* <Th >taxPrice</Th> */}
              <Th >Product</Th>
              <Th >Action</Th> 
            </Tr>
          </Thead>
          <Tbody>
            {
              adminOrder && adminOrder.map((item, i) => (
                <Tr key={i}>
                    <Td >
                      {item.orderStatus}
                      <IconButton colorScheme={'orange'} variant={'ghost'} color={'white'} onClick={() => openStatusHandler(item._id)} >
                              <GrUpdate  />
                      </IconButton>

                      <Modal isOpen={isOpen} onClose={onClose}>
							          {/* <ModalOverlay /> */}
							        <ModalContent>
								        <ModalHeader>Change Order Status</ModalHeader>
								        <ModalCloseButton />
								        <ModalBody>
									        <HStack justifyContent={'space-evenly'} alignItems={'center'}  >
                            <Select  required onChange={(e)=>{setStatus(e.target.value)}} value={status} type={'text'} placeholder='Select Status'>
                              <option value={'Processing'}>Processing </option>                                                  
                              <option value={'Delivered'}>Delivered </option>                                                  
                              <option value={'Dispatch'}>Dispatch </option>                                                                                
                            </Select>

                            <Button isLoading={loading} variant={'solid'} colorScheme={'orange'} onClick={() => updateOrderStatus(orderId)} >
                              Update 
                            </Button>
									  

									        </HStack>
								        </ModalBody>

							        </ModalContent>
						          </Modal>
                       
                    </Td>
                    <Td >{item?.user?.name}</Td>
                    {/* <Td >{item.totalPrice}</Td> */}
                    {/* <Td >{item.shippingPrice}</Td> */}
                    {/* <Td >{item.taxPrice}</Td> */}

                  {/* product nested table */}
                    <Td  w={'100%'}>                      
                      <Table variant='striped' size={'lg'}>
                        <Thead>
                          <Tr>
                            {/* <Th>poster</Th> */}
                            <Th>name</Th>
                            <Th>items price</Th>
                            {/* <Th >Product Id</Th> */}
                            <Th>price</Th>
                            <Th >qty</Th> 
                          </Tr>
                        </Thead>
                      </Table>
                      <Tbody w={'100%'}>
                        {
                          item.orderProduct && item.orderProduct.map((pro)=>(
                              <Tr w={'100%'} >
                                  {/* <Td  w={'100%'}>
                                      <Image w={'100%'} src={pro.image} alt='poster' aspectRatio={'1/1'} />
                                  </Td> */}
                                  <Td >{pro.name}</Td>
                                  <Td >{pro.quantity*pro.price}</Td>
                                  {/* <Td >{pro.product}</Td> */}
                                  <Td >{pro.price}</Td>
                                  <Td >{pro.quantity}</Td>
                              </Tr>
                          ))
                        }
                      </Tbody>                        
                    </Td>

                    <Td isNumeric>
                        <HStack justifyContent={'flex-end'}>
                          {/* <Button
                            variant={'outline'}
                            color={'purple.400'}
                            onClick={() => ProductDetailHandler(item._id)}
                            isLoading={loading}
                            >
                            View Product
                          </Button> */}
                          <Button isLoading={loading} color={'purple.600'} onClick={() => deleteHandler(item._id)} >
                            <RiDeleteBin7Fill />
                          </Button>
                        </HStack>
                    </Td>

                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </TableContainer>
      {/* <ProductModel
        isOpen={isOpen}
        onClose={onClose}
        deleteButtonHandler={deleteHandler}
        addLectureHandler={addLectureHandler}
        id={courses._id}
        lecture={courses.lecture}
        courseTitle={courses.title}
        isLoading={loading}
      /> */}
    </Box>
            </Grid>
        </>
    )
}

export default AdminOrder